<style lang="less" scoped>
  .unit-page {
    display: flex;
    flex-direction: column;
  }
  .item-list {}
  .item {
    transition: all .3s;
    padding: 10px;
    border-radius: 5px;
    border: 1px dashed transparent;
    &:hover {
      border-color: #dedede;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .name {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #515a6e;
  }
  .info {
    margin-top: 5px;
  }
  .remark {
    margin-bottom: 5px;color: #999
  }
  .names {
    white-space: pre-wrap;
  }
</style>

<template>
  <div class="unit-page">
    <div v-if="!status.form">
      <Input search enter-button clearable placeholder="人员/编码/名称搜索" @on-search="value => search = value" @on-clear="search = null" />
    </div>
    <div style="margin-top: 10px;flex: 1;height: 0;overflow: auto;" v-loadingx="loading.load">
      <template v-if="status.form">
        <fm-title :title-text="(formData.id ? '修改' : '新增') + '考核单元'"></fm-title>
        <fm-form label-alone label-align="left">
          <fm-form-item label="名称">
            <fm-input-new v-model="formData.name" placeholder="请输入名称"/>
          </fm-form-item>
          <fm-form-item label="管理员">
            <Select placeholder="请选择管理员" multiple filterable clearable v-model="formData.manageUserIds">
              <Option v-for="item in userList" :value="item.key" :key="item.key">{{item.label}}</Option>
            </Select>
          </fm-form-item>
          <fm-form-item label="类型">
            <Select placeholder="请选择类型" filterable clearable v-model="formData.type">
              <Option v-for="item in pfmUnitType" :value="item.value" :key="item.value">{{item.label}}</Option>
            </Select>
          </fm-form-item>
          <fm-form-item label="编码">
            <fm-input-new v-model="formData.code" placeholder="请输入编码"/>
          </fm-form-item>
          <fm-form-item label="备注">
            <fm-input-new v-model="formData.remark" placeholder="请输入备注"/>
          </fm-form-item>
        </fm-form>
        <div style="margin-top: 10px;display: flex;">
          <Button :loading="loading.submit" style="flex: 1;" @click="status.form = false" type="default" long>取消</Button>
          <Button :loading="loading.submit" style="flex: 1;margin-left: 10px;" @click="submit" type="info" long>确定</Button>
        </div>
      </template>
      <template v-else>
        <div class="item-list">
          <div class="item" :name="index" v-for="(item, index) in showDataList" :key="item.id">
            <div class="title">
              <div class="name"><Tag style="margin-right: 10px;" :color="item.typeColor">{{item.typeText}}</Tag>{{item.name + ' - ' + (item.code || '')}}</div>
              <div v-if="$authFunsProxyNoRoute['performance.org-unitManage']">
                <Button style="margin-right: 5px;" @click="itemEdit(item)" size="small" type="default">修改</Button>
                <Poptip transfer confirm @on-ok="itemDel(item)" title="确定删除吗？">
                  <Button size="small" type="default">删除</Button>
                </Poptip>
              </div>
            </div>
            <div class="info">
              <p class="remark">{{item.remark || '暂无备注'}}</p>
              <p class="names">
                <Tag v-for="(name, i) in item.manageUserNames" :key="i">{{name}}</Tag>
              </p>
            </div>
          </div>
        </div>
        <TableEmpty style="display: flex;align-items: center;flex-direction: column;" v-if="!dataList.length" />
      </template>
    </div>
    <div v-if="!status.form && $authFunsProxyNoRoute['performance.org-unitManage']" style="margin-top: 10px;">
      <Button @click="addClick" type="info" long>新增考核单元</Button>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
import TableEmpty from '@/components/base/TableEmpty.vue'

function getDefaultFormData () {
  return {
    id: null,
    manageUserIds: [],
    name: null,
    type: null,
    code: null,
    remark: null
  }
}

function parseData (data) {
  return JSON.parse(JSON.stringify(data), (key, value) => {
    if (key === 'manageUserIds' && !Array.isArray(value)) {
      if (value) {
        return value.split(',').map(v => Number(v))
      } else {
        return []
      }
    }
    return value
  })
}

function transData (data) {
  return JSON.parse(JSON.stringify(data), (key, value) => {
    if (key === 'manageUserIds' && value && Array.isArray(value)) {
      return value.join(',')
    }
    return value
  })
}

export default {
  components: { TableEmpty },
  data () {
    return {
      dataList: [],
      formData: getDefaultFormData(),
      status: {
        form: false
      },
      loading: {
        submit: false,
        load: false
      },
      search: null
    }
  },
  computed: {
    pfmUnitType () {
      return this.$store.getters.pfmUnitType
    },
    userList () {
      return this.$store.getters.userList
    },
    showDataList () {
      const search = this.search
      return search ? this.dataList.filter(item => {
        if (item.name && item.name.indexOf(search) > -1) {
          return true
        }
        if (item.code && item.code.indexOf(search) > -1) {
          return true
        }
        if (item.manageUserNames && item.manageUserNames.some(v => v.indexOf(search) > -1)) {
          return true
        }
        return false
      }) : this.dataList
    }
  },
  methods: {
    async loadData () {
      if (this.$authFunsProxyNoRoute['performance.org-unitGet']) {
        this.loading.load = true
        this.dataList = await this.$store.dispatch('loadPfmUnitList', true)
        this.loading.load = false
      }
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    itemEdit (item) {
      this.formData = parseData(item)
      this.status.form = true
    },
    async itemDel (item) {
      await request('/performance/pfm_unit/' + item.id, 'delete')
      this.$Message.success('已删除')
      this.loadData()
    },
    async submit () {
      try {
        this.loading.submit = true
        if (this.formData.id) {
          await request('/performance/pfm_unit/' + this.formData.id, 'put', transData(this.formData))
        } else {
          await request('/performance/pfm_unit', 'post', transData(this.formData))
        }
        this.status.form = false
        this.$emit('refresh')
        this.$Message.success('操作成功')
        this.loadData()
        this.loading.submit = false
      } catch (e) {
        this.loading.submit = false
      }
    }
  },
  mounted() {
    this.$store.dispatch('loadUserList')
    this.loadData()
  }
}
</script>
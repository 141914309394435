<style lang="less" scoped>
  .org-page {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .toolbox {
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
  .query-form {
    margin-top: 10px;
  }
  .table {
    margin-top: 10px;
    height: 0;
    flex: 1;
  }
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<template>
  <div class="org-page">
    <div class="toolbox">
      <div class="title">
        <fm-title title-text="绩效科室"></fm-title>
        <div>
          <Button :loading="loading.load" style="margin-right: 10px;" icon="md-refresh-circle" @click="loadData">刷新</Button>
          <Button style="margin-right: 10px;" v-if="$authFunsProxyNoRoute['performance.org-unitGet']" icon="ios-apps" @click="status.unitPage = true">考核单元管理</Button>
          <Button style="margin-right: 10px;" v-if="$authFunsProxyNoRoute['performance.org-unitConfig']" icon="md-construct" @click="unitConfigClick">考核单元明细配置</Button>
          <Poptip style="margin-right: 10px;" v-if="$authFunsProxyNoRoute['performance.org-manage']" confirm @on-ok="dataSync" title="确定同步吗？">
            <Button :loading="loading.sync" type="warning" icon="md-sync">数据同步</Button>
          </Poptip>
          <Button icon="md-add-circle" v-if="$authFunsProxyNoRoute['performance.org-manage']" @click="addClick" type="primary">新增绩效科室</Button>
        </div>
      </div>
      <fm-form class="query-form" inline>
        <fm-form-item style="margin-bottom: 0;" label="名称">
          <fm-input-new clearable v-model="query.name" />
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="机构">
          <Select filterable clearable v-model="query.orgId">
            <Option v-for="item in orgList" :value="item.key" :key="item.key">{{ item.label }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="考核单元">
          <Select filterable clearable v-model="query.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="his编码">
          <fm-input-new clearable v-model="query.hisCode" />
        </fm-form-item>
      </fm-form>
    </div>
    <div class="table" ref="table">
      <Table :loading="loading.load" v-if="tableHeight !== null" :height="tableHeight" :columns="columns" :data="showDataList">
        <template slot-scope="{ row }" slot="action">
          <Button @click="itemEdit(row)" icon="ios-brush" size="small" type="text">修改</Button>
          <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button icon="md-trash" size="small" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </div>
    <Drawer title="考核单元管理" draggable :mask-closable="false" width="40" placement="right" closable v-model="status.unitPage">
      <UnitPage v-if="status.unitPage" style="height: 100%;" />
    </Drawer>
    <Drawer :title="(formData.id ? '修改' : '新增') + '绩效科室'" draggable :mask-closable="false" width="30" placement="right" closable v-model="status.form">
      <fm-form label-alone label-align="left">
        <fm-form-item label="名称">
          <fm-input-new placeholder="请输入名称" v-model="formData.name" />
        </fm-form-item>
        <fm-form-item label="机构">
          <Select placeholder="请选择机构" filterable clearable v-model="formData.orgId">
            <Option v-for="item in orgList" :value="item.key" :key="item.key">{{ item.label }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="考核单元">
          <Select placeholder="请选择考核单元" filterable clearable v-model="formData.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="his编码">
          <fm-input-new placeholder="请输入his编码" v-model="formData.hisCode" />
        </fm-form-item>
        <fm-form-item label="">
          <div style="display: flex;">
            <Button :loading="loading.submit" style="flex: 1;" @click="status.form = false" type="default">取消</Button>
            <Button :loading="loading.submit" style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'
import UnitPage from '../unit/index'

function getDefaultQuery () {
  return {
    name: null,
    pfmUnitId: null,
    orgId: null,
    hisCode: null
  }
}

function getDefaultFormData () {
  return {
    id: null,
    name: null,
    orgId: null,
    pfmUnitId: null,
    hisCode: null
  }
}

export default {
  components: { UnitPage },
  data () {
    return {
      dataList: [],
      query: getDefaultQuery(),
      formData: getDefaultFormData(),
      status: {
        unitPage: false,
        form: false
      },
      loading: {
        load: false,
        submit: false,
        sync: false
      },
      tableHeight: null
    }
  },
  computed: {
    unitList () {
      return this.$store.getters.pfmUnitList
    },
    columns () {
      const columns = [
        { title: '名称', key: 'name' },
        { title: '机构', key: 'orgName' },
        { title: '考核单元', key: 'pfmUnitName' },
        { title: 'his编码', key: 'hisCode' },
      ]

      if (this.$authFunsProxyNoRoute['performance.org-manage']) {
        return [
          ...columns,
          {
            title: '操作', key: 'action', fixed: 'right',
            slot: 'action', width: 170
          }
        ]
      }

      return columns
    },
    showDataList () {
      const query = this.query
      const match = Object.keys(query).filter(v => query[v]).map(v => {
        return { key: v, value: query[v] }
      })
      return match.length > 0 ? this.dataList.filter(item => {
        return match.every(v => {
          if (typeof v.value !== 'string') {
            return item[v.key] === v.value
          } else {
            return String(item[v.key]).indexOf(v.value) > -1
          }
        })
      }) : this.dataList
    },
    orgList () {
      return this.$store.getters.orgList
    }
  },
  methods: {
    unitConfigClick () {
      this.$router.push({
        name: 'performance.unit.config',
        query: { back: true }
      })
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.form = true
    },
    async itemDel (item) {
      const confirm = await this.$dialog.confirm('确定删除吗？')
      if (confirm) {
        await request('/performance/pfm_org/' + item.id, 'delete')
        this.$Message.success('已删除')
        this.loadData()
      }
    },
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_org/sync', 'post')
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    async loadData () {
      if (this.$authFunsProxyNoRoute['performance.org-get']) {
        this.loading.load = true
        this.dataList = await this.$store.dispatch('loadPfmOrgList', true)
        this.loading.load = false
      }
    },
    resize () {
      this.tableHeight = this.$refs.table.offsetHeight
    },
    async submit () {
      this.loading.submit = true
      try {
        if (this.formData.id) {
          await request('/performance/pfm_org/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/performance/pfm_org', 'post', this.formData)
        }
        this.status.form = false
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.loading.submit = false
      }
    }
  },
  mounted() {
    this.loadData()
    this.$store.dispatch('loadOrgList')
    this.$store.dispatch('loadPfmUnitList')
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>